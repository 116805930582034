.searchBorder {
  max-height: 250px;
  width: 100%;
  position: absolute;
  z-index: 500;
  border: solid 2px transparent;
  border-radius: 8px;
  padding: 1rem 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.75rem;
  background: linear-gradient(white, white), black;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
  overflow-y: auto;
}

/* width */
.searchBorder::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.searchBorder::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.searchBorder::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.searchBorder::-webkit-scrollbar-thumb:hover {
  background: #555;
}
